import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';
import ResponsiveImg from '../../components/ResponsiveImg';
import { openFindNeosurf, commonHeaderColor } from '../../util';


const IndexPage = ({ data }) => (
  <>
    <SEO
      title="Achète Neosurf - Bons, Codes &amp; Casinos en ligne Neosurf"
      description="Achète des codes de &amp; bons de Neosurf and paie en toute sécurité sur Web. Consulte la liste des casinos de Neosurf réputés en ligne."
      alternateLanguagePages={[
        {
          path: '',
          language: 'en'
        },
        {
          path: '',
          language: 'fr'
        },
        {
          path: '',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.img1.childImageSharp.fluid} alt="Achète Neosurf &amp; paie en toute sécurité en ligne" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1>Achète Neosurf &amp; Paie sur Web</h1>
            <p className="textColorDark">Si tu souhaites continuer à effectuer des paiements en ligne, mais que tu n’aimes pas avoir à donner des informations sensibles, pourquoi ne pas essayer le <strong>Neosurf</strong>? C’est une <strong>méthode de paiement sûre et sécurisée</strong> qui implique l’utilisation de bons. Il est conçu pour rendre les paiements en ligne beaucoup plus sûrs et plus pratiques.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">POURQUOI NEOSURF ?</h2>
            <p>Il existe aujourd’hui une dizaine de méthodes de paiement différentes. Les casinos en ligne acceptent généralement n’importe quoi, de quelques-uns à plus de 20 d’entre eux. Avec autant de choix disponibles, pourquoi devrais-tu choisir le Neosurf comme méthode de paiement ?</p>
            <p>L’une des principales raisons pour lesquelles tu devrais utiliser le Neosurf est qu’il est très sûr et très sécurisé. Lorsque tu l’utilises en ligne, tu <strong>n’as pas besoin de fournir tes coordonnées personnelles ou bancaires</strong>. En effet, le Neosurf est un système de bons. Il est accepté sur de nombreux sites de jeux, de jeux d’argent et de divertissement en général, ainsi que d’autres nombreux sites Web. Tu peux même l’utiliser pour recharger ton solde sur diverses cartes prépayées et portefeuilles électroniques.</p>
            <p>L’industrie du jeu en ligne évolue quotidiennement. Certains des portefeuilles électroniques et des méthodes de paiement les plus populaires ne sont plus disponibles sur certains marchés. C’est là que le Neosurf se cache. Tu peux acheter des codes de Neosurf dans plus de <strong>135 000 points de vente</strong> partout dans le monde entier.</p>
          </div>
        </div>
        <div>
          <Img fluid={data.img2.childImageSharp.fluid} alt="Codes de Neosurf disponibles dans plus de 135 000 magasins dans le monde entier" />
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textAlignCenter textUppercase">OBTIENS NEOSURF - 3 ETAPES FACILES</h2>
      <p className="textAlignCenter">L’une des raisons pour lesquelles le Neosurf est un bon moyen de paiement est sa commodité.<br />Son utilisation est en effet très simple. En fait, il n’y a que trois étapes à suivre.</p>
      <div className="grid c-3 bigGap alignItemsTop">
        <div>
          <div className="contentBox border accent textAlignCenter">
            <h3 className="smaller">1. Trouver un revendeur</h3>
            <p>Tout d’abord, tu dois te rendre dans un <strong>point de vente</strong>. Il existe plus de 135 000 de ces endroits dans le monde. Pour trouver le plus proche de chez toi, il te suffit de suivre ce lien.</p>
          </div>
        </div>
        <div>
          <div className="contentBox border accent textAlignCenter">
            <h3 className="smaller">2. Achète un code NIP</h3>
            <p>Ensuite, tu dois acheter un <strong>bon de Neosurf</strong>. Les bons peuvent être achètes en plusieurs sommes différents allant de 10 € à 200 €. Chaque bon que tu achètes comporte un code NIP à 10 chiffres.</p>
          </div>
        </div>
        <div>
          <div className="contentBox border accent textAlignCenter">
            <h3 className="smaller">3. Paie en ligne !</h3>
            <p>Finalement, lorsque tu souhaites utiliser le Neosurf en ligne, entre simplement le <strong>code NIP à 10 chiffres</strong>. Tout l’argent restant peut être dépensé en utilisant le même code. Alternativement, tu peux aller en ligne et le transférer vers un nouveau bon.</p>
          </div>
        </div>
      </div>
      <p className="textAlignCenter">Pour vérifier le solde d’un bon, visite justement le site Web de Neosurf et rends-toi dans la sélection 'Ma carte'.<br />Entre ton code NIP à 10 chiffres pour voir le solde de ce bon particulier.</p>
    </div>
    <ContentRow backgroundColor="#eee">
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">PAIE &amp; JOUE EN LIGNE AVEC NEOSURF</h2>
            <p className="textColorDark">Ne souhaites-tu pas utiliser de cartes de débit / crédit pour payer en ligne ? Ou alors, n’en as-tu pas encore une ? Ne souhaites-tu peut-être pas fournir de coordonnées bancaires sensibles, car la confidentialité est ta préoccupation principale ?</p>
            <p>Neosurf est probablement la <strong>solution idéale pour toi</strong> ! Achète un bon de Neosurf en espèces et paie et joue en ligne en toute sécurité. Tout simple que cela ! Regarde une courte vidéo expliquant le fonctionnement du système de bons de Neosurf.</p>
          </div>
        </div>
        <div>
          <div className="videoWrapper">
            <iframe
              title="neosurfVideo"
              src="https://www.youtube.com/embed/ZZKglPJVhFE"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            >
            </iframe>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow
      backgroundColor="rgba(255,0,155, 0.8)"
      Img={
        <ResponsiveImg
          aspectRatio={data.img3.childImageSharp.fixed.aspectRatio}
          src={data.img3.childImageSharp.fixed.src}
          alt=""
        />
      }
      padV
    >
      <div className="contentBox textAlignCenter">
        <h2 className="smaller textColorComplementary">Trouve un revendeur de Neosurf près de chez toi</h2>
        <p className="textColorLight">Les clients aiment les bons de caisse de Neosurf car ils sont disponibles dans des milliers d’endroits pratiques à travers le monde. Aujourd’hui, tu trouveras des bons de caisse de Neosurf dans <strong className="textColorComplementary">les dépanneurs, les supermarchés, les stations-service, les kiosques à journaux, les bureaux de tabac, les kiosques, les cybercafés et d’autres</strong>.</p>
        <button className="button big" onClick={openFindNeosurf}>Où trouver  Neosurf?</button>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textAlignCenter textUppercase">CASINOS DE NEOSURF &amp; PARTENAIRES</h2>
      <p className="textAlignCenter">L’une des nombreuses choses pour lesquelles tu peux utiliser le Neosurf est de financer des activités de jeu en ligne. Plus précisément, tu peux l’utiliser pour déposer de l’argent sur des comptes de casino en ligne. Un nombre croissant de casinos ont commencé à accepter ce mode de paiement en raison de sa sureté et sa sécurité. Découvres <strong>les casinos</strong> &amp; partenaires de Neosurf recommandés.</p>
      <div className="grid c-3 bigGap" style={{ alignItems: 'flex-start' }}>
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.luckystar.childImageSharp.fluid} alt="" />
            </a>
            <h3>LuckyStar</h3>
            <p className="zeroMarginT">Guidé par la devise <strong>"Les joueurs heureux sont la meilleure publicité"</strong>, le casino LuckyStar représente une véritable vedette dans l’industrie du jeu en ligne. Plus de 1000 jeux de casino, une large gamme de méthodes de paiement, y compris le Neosurf et un soutien clientèle primée, te garantissent une superbe expérience de jeu. Inscris-toi aujourd’hui et réclame <strong>100% de bonus de bienvenue</strong>.</p>
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" className="button">Visitez Maintenant</a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.betbtc.io/sign-up/a48acf12">
              <Img fluid={data.betbtc.childImageSharp.fluid} alt="" />
            </a>
            <h3>Casino BetBTC</h3>
            <p className="zeroMarginT">BetBTC est un casino 'hybride' fiduciaire &amp; crypto, ce qui signifie que ton compte sera en monnaie fiduciaire, cependant tu pourras déposer et retirer avec les deux méthodes de transfert fiduciaire et BITCOIN, qui sera automatiquement échangé contre sa valeur fiduciaire! Avec une conception moderne, une plateforme rapide, une vaste gamme de jeux et les meilleurs bons - le Casino BetBTC  a vraiment tout pour plaire! Oh oui, il acceptera des joueurs de partout!</p>
            <a href="https://www.betbtc.io/sign-up/a48acf12" className="button">Visitez Maintenant</a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.auslots.childImageSharp.fluid} alt="" />
            </a>
            <h3>Casino AUSlots</h3>
            <p className="zeroMarginT">Cherches-tu des jeux de machine à sous géniaux? Des machines à sous en ligne uniques et modernes? AUSlots est peut-être l’endroit idéal pour toi! Avec plus de <strong>1000 jeux de casino</strong> y compris les dernières machines à sous et les jeux de casino en direct, AUSlots offre un excellent divertissement et une expérience de jeu inégalée. Inscris-toi aujourd’hui et gagne 150 $ / € sur la Maison !</p>
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" className="button">Visitez Maintenant</a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.emu.childImageSharp.fluid} alt="" />
            </a>
            <h3>Casino Emu</h3>
            <p className="zeroMarginT">Casino Emu est l’un des casinos en ligne australiens le mieux noté qui accepte la méthode de dépôt Neosurf Plus d’un millier de jeux des meilleurs fournisseurs de jeux au monde, dont plus de 900 machines à sous de poker!</p>
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" className="button">Visitez Maintenant</a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.slotsBay.childImageSharp.fluid} alt="" />
            </a>
            <h3>777 Slots bay</h3>
            <p className="zeroMarginT">Le 777SlotsBay, un nouveau casino en ligne d’une équipe avec des années d’expérience, te propose toutes les dernières et meilleures machines à sous et les jeux de casino, jouables sur tous les appareils. Et il accepte le Neosurf!</p>
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" className="button">Visitez Maintenant</a>
          </div>
        </div>
      </div>
      <h2 className="smaller textAlignCenter textUppercase zeroMarginB">FAQ</h2>
      <div className="grid c-2 bigGap">
        <div>
          <Img className="roundedBorder" fluid={data.img4.childImageSharp.fluid} alt="Retraits utilisant Neosurf" />
        </div>
        <div>
          <div className="contentBox">
            <h3 className="smaller textUppercase">PUIS-JE ME RETIRER DES CASINOS EN LIGNE EN UTILISANT NEOSURF?</h3>
            <p>Malheureusement, il n’est pas possible d’effectuer des retraits avec ce mode de paiement. Les casinos te permettront d’utiliser un autre mode de paiement pour retirer tes fonds.</p>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <h3 className="smaller textUppercase">SERAI-JE FACTURÉ POUR L’UTILISATION DE CE MÉTHODE DE PAIEMENT?</h3>
            <p>Tu ne seras facturé si tu achètes des bons dans une devise et que tu les utilises pour effectuer des paiements dans une autre devise. Dans ce cas, une commission de change de 2% (3% pour les transactions impliquant le zloty polonais) sera débitée. Des frais d’inactivité de 2 € par mois sont également facturés aux comptes de bons qui sont inactifs depuis au moins six mois ou un an après l’achat.</p>
          </div>
        </div>
        <div>
          <Img className="roundedBorder" fluid={data.img5.childImageSharp.fluid} alt="Questions demandes fréquemment" />
        </div>
      </div>
    </div>
  </>
)

export default IndexPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    img1: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/mac1.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 1080,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/model-card.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "bg2.jpg"}) {
      childImageSharp {
        fixed(width: 524) {
          aspectRatio
          src
        }
      }
    }
    img4: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/home_4.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 505,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/home_6.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 505,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    luckystar: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/ls.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    betbtc: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/betbtc.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 600,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    auslots: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/AUSlots-Casino-home.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    emu: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/emucasino-logo.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slotsBay: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/777-slots-bay-logo-getneosurf.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
